import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/atoms/seo"
import Layout from "../components/layout"
import PageTitle from "../components/atoms/page-title/page-title"
import img from "../images/honoraires.jpg"
import "./honoraires.scss"

const HonorairesPage = () => {
  const { allWordpressPage } = useStaticQuery(
    graphql`
      query {
        allWordpressPage(filter: { title: { eq: "honoraires-presentation" } }) {
          edges {
            node {
              id
              title
              content
            }
          }
        }
      }
    `
  )
  return (
    <Layout>
      <SEO title="Mopo Avocats Honoraires" />
      <PageTitle title="honoraires" />
      <section className="section">
        <div className="content columns">
          <div className="column is-half-desktop">
            <div className="honoraires-description is-family-secondary has-text-right is-size-4">
              {allWordpressPage.edges[0]?.node.content && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: allWordpressPage.edges[0]?.node.content,
                  }}
                />
              )}
            </div>
          </div>
          <div className="column is-half-desktop">
            <div className="honoraires-image-wrapper">
              <img src={img} alt="honoraires" />
              <div className="img-shadow" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default HonorairesPage
